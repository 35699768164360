import React from 'react';
import { motion } from 'framer-motion';
import AnimatedText from './AnimatedText';
import coder3d from '../../assets/images/coder3d.png';

const Hero = () => {
  const lines = [
    'Building full-stack Websites/Apps',
    'Crafting intuitive, beautiful UI/UX',
    'Innovating tech for real-world problems',
  ];

  return (
    <section className='flex flex-col mt-0 lg:mt-8 my-auto self-center sm:text-sm items-center justify-center'>
      <motion.div
        className='text-center'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h1
          className='text-3xl text-black lg:text-4xl w-[100vw] font-bold mb-8'
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Hi, I'm <span className='name'>Bharat Pahwa!</span>
        </motion.h1>
        <AnimatedText className='self-center items-center' lines={lines} />
        <motion.p
          className='mt-0 font-semibold text-base lg:text-lg'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          is my expertise.{' '}
        </motion.p>
        <motion.img
          className='h-36 self-center mb-2 m-auto mt-10'
          src={coder3d}
          alt='Coder 3D'
          animate={{ y: [0, 10, 0] }}
          transition={{ duration: 2, repeat: Infinity, ease: 'easeInOut' }}
        />
      </motion.div>
    </section>
  );
};

export default Hero;
