import React from 'react';
import { NavLink } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const ProjectsRef = ({ id }) => {
  return (
    <>
      <section
        id={id}
        className='bg-blue-600 m-auto mt-8 rounded-xl mx-4 pb-4 lg:mx-28 md:p-8'
      >
        <p className='bg-blue-600 pt-6 mx-10 flex justify-center text-white text-4xl lg:text-5xl py-0'>
          My <span className='name'> {'  '}Projects</span>
        </p>
        <div className='mx-auto p-2 max-w-5xl pl-4 lg:pl-0'>
          <ProjectLink
            heading='Development'
            subheading='Coded with ❤️ and various technologies'
            to='/devprojects' // Updated
          />
          <ProjectLink
            heading='Design'
            subheading='Designed with ❤️ and figma'
            to='/designprojects' // Example for another project type, change if needed
          />
        </div>
      </section>

      <section
        id={id}
        className='bg-blue-600 m-auto mt-4 rounded-xl mx-4 pb-4 pt-2 lg:mx-28 md:p-8'
      >
        <h2 className='text-4xl mt-4 text-white text-center m-auto md:text-5xl'>
          Currently
          <span className='text-white name'>Building</span>
        </h2>
        <div className='mx-auto p-2 max-w-5xl pl-4 lg:pl-0'>
          <div>
            <WebsiteLink
              heading='EngageGPT'
              subheading='AI for LinkedIn'
              to='https://engagegpt.in'
            />
          </div>{' '}
          <div>
            <WebsiteLink
              heading='Frostmail'
              subheading='Successfull cold mail outreach'
              to='https://cold-email-tau.vercel.app/'
            />
          </div>
        </div>
      </section>
    </>
  );
};

const ProjectLink = ({ heading, subheading, to }) => {
  return (
    <NavLink
      to={to}
      className='group relative flex items-center justify-between border-b-2 border-neutral-300 py-4 transition-colors duration-500 hover:border-neutral-50 md:py-8'
    >
      <div>
        <span className='relative z-10 block text-2xl font-bold text-neutral-50 transition-colors duration-500 group-hover:text-neutral-50 md:text-5xl'>
          {heading}
        </span>
        <span className='relative z-10 mt-2 block text-sm lg:text-base text-neutral-50 transition-colors duration-500 group-hover:text-neutral-50'>
          {subheading}
        </span>
      </div>
      <div className='relative z-10 p-4'>
        <FiArrowRight className='text-3xl mr-0 text-white' />
      </div>
    </NavLink>
  );
};
const WebsiteLink = ({ heading, subheading, to }) => {
  return (
    <a
      href={to}
      target='_blank'
      rel='noreferrer'
      className='group relative flex items-center justify-between border-b-2 border-neutral-300 py-4 transition-colors duration-500 hover:border-neutral-50 md:py-8'
    >
      <div>
        <span className='relative z-10 block text-2xl font-bold text-neutral-50 transition-colors duration-500 group-hover:text-neutral-50 md:text-5xl'>
          {heading}
        </span>
        <span className='relative z-10 mt-2 block text-sm lg:text-lg text-neutral-50 transition-colors duration-500 group-hover:text-neutral-50'>
          {subheading}
        </span>
      </div>
      <div className='relative z-10 p-4'>
        <FiArrowRight className='text-3xl mr-0 text-white' />
      </div>
    </a>
  );
};

export default ProjectsRef;
