import React from 'react';
import Footer from '../common/Footer';
import ProjectHighlights from './ProjectHighlights';

const DevelopmentProjects = () => {
  return (
    <section className=''>
      <ProjectHighlights />
      <Footer />
    </section>
  );
};

export default DevelopmentProjects;
