import LaptopHello from '../../assets/images/LaptopHello.png';
import StandingHello from '../../assets/images/StandingHelllo.png';
import RocketHello from '../../assets/images/RocketHello.png';
import UpsideHello from '../../assets/images/UpsideHello.png';
import GetInTouch from './GetInTouch';
import AnimatedImages from '../home/AnimatedImages';

const leftImages = [
  { src: LaptopHello, alt: 'Code 3D', className: 'code3d', height: '12rem' },
  {
    src: StandingHello,
    alt: 'Smiling Robot',
    className: 'smilingRobot',
    height: '11rem',
  },
];

const rightImages = [
  { src: RocketHello, alt: 'rocket', className: 'rocket', height: '12rem' },
  { src: UpsideHello, alt: 'API', className: 'API', height: '12rem' },
];

const Contact = ({ id }) => {
  return (
    <div
      id={id}
      className='relative flex flex-col h-[80vh] lg:min-h-[110vh] mt-0'
    >
      <div className='relative flex-grow'>
        <div className='absolute inset-0 overflow-hidden items-center'>
          <div className='desktop-only'>
            <AnimatedImages side='left' images={leftImages} />
          </div>
          <GetInTouch />
          <div className='desktop-only'>
            <AnimatedImages side='right' images={rightImages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
