import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import BotComponent from './BotComponent';
import { motion } from 'framer-motion';
import { useState } from 'react';

const ResumeSection = () => {
  const [isBotVisible, setIsBotVisible] = useState(false);

  const handleBotOpen = () => {
    setIsBotVisible(true);
  };

  const handleBotClose = () => {
    setIsBotVisible(false);
  };
  return (
    <section className='flex flex-col items-center mt-6 w-full liners'>
      <p className='mt-2 mb-4 font-light text-base sm:text-lg text-center w-full lg:w-6/12'>
        Wanna transform your ideas into reality?
      </p>
      <div className='flex gap-4'>
        <motion.button
          onClick={handleBotOpen}
          className='btn rounded-full border-2 border-white bg-black text-white hover:bg-white hover:border-black hover:text-black py-[14px] px-6 flex items-center justify-center'
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0 }}
        >
          <span className='font-normal'>Let's Chat</span>
          <FaArrowRight className='arrow ml-2 rotate-[325deg]' />
        </motion.button>
        <a
          href='https://calendly.com/bharatpahwa-work/hangout-session'
          target='_blank'
          rel='noopener noreferrer'
          className='btn rounded-full bg-white hover:bg-black hover:text-white border-black border-2  text-black py-2 px-6 flex items-center justify-center'
        >
          <span className='font-normal'>Book a Call</span>
          <FaArrowRight className='arrow ml-2 rotate-[325deg]' />
        </a>
      </div>
      <BotComponent isVisible={isBotVisible} onClose={handleBotClose} />
    </section>
  );
};

export default ResumeSection;
