import React, { useEffect } from 'react';

const GetInTouch = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://collectcdn.com/embed.js';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div id='chat' className='get-in-touch-section p-4'>
      <h2 className='text-5xl name font-bold text-center mb-12 animate-fadeIn'>
        Get in touch
      </h2>
      <div className='flex flex-col lg:flex-row items-center justify-center lg:items-start space-y-8 lg:space-y-0'>
        <div className='chatbot-section lg:w-1/2 w-[50vh]'>
          <div className='elementor-widget-container rounded-xl border-2 overflow-hidden w-full animate-fadeIn'>
            <div
              className='elementor-shortcode overflow-hidden'
              dangerouslySetInnerHTML={{
                __html: `
                  <iframe title="Chatbot" data-src="https://links.collect.chat/669e9e5a3e99425e992deaa9" width="100%" height="460" frameborder="0" src="https://links.collect.chat/669e9e5a3e99425e992deaa9" class="lazyloaded" data-load-mode="1"></iframe>
                  <script async type="text/javascript" src="https://collectcdn.com/embed.js"></script>
                `,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
