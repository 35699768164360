import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import Header from './Header';
import Footer from './Footer';
import Navbar from './Navbar';
import HeroSection from '../home/HeroSection';
import TrippyScroll from '../home/TrippyScroll';
import TechStack from '../experience/TechStack';
import Experience from '../experience/Experience';
import ProjectsRef from '../projects/ProjectsRef';
import Achievements from '../achievements/Achievements';
import Certifications from '../certifications/Certifications';
import Education from '../education/Education';
import Contact from '../contact/Contact';
import { Analytics } from '@vercel/analytics/react';

const Layout = () => {
  const ScrollToSection = () => {
    const location = useLocation();

    useEffect(() => {
      const hash = window.location.hash.replace('#', '');
      if (hash) {
        scroller.scrollTo(hash, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -70, // Adjust the offset for sticky header
        });
      }
    }, [location]);

    return null;
  };

  return (
    <>
      <Header />
      <HeroSection id='home' />
      <TrippyScroll id='trippyscroll' />
      <TechStack id='techstack' />
      <Experience id='experience' />
      <ProjectsRef id='projects' />
      <Achievements id='achievements' />
      <Certifications id='certifications' />
      <Education id='education' />
      <Contact id='chat' />
      <Footer id='contact' />
      <Navbar />
      <ScrollToSection />
      <Analytics />
    </>
  );
};

export default Layout;
