import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Import close icon from FontAwesome

const BotComponent = ({ isVisible, onClose }) => {
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isVisible ? 'visible' : 'invisible'
      }`}
    >
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isVisible ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>
      <div
        className={`relative bg-white p-6 rounded-lg shadow-lg z-50 w-[96%] md:w-[50%] ${
          isVisible ? 'visible' : 'invisible'
        }`}
      >
        <button
          className='absolute top-[-3rem] right-0 text-balck rounded-full bg-white p-2 hover:bg-black hover:text-white'
          onClick={onClose}
        >
          <FaTimes size={18} />
        </button>
        <div
          className='elementor-widget-container rounded-xl border-2 overflow-hidden w-full'
          dangerouslySetInnerHTML={{
            __html: `
              <iframe data-src="https://links.collect.chat/669e9e5a3e99425e992deaa9" width="100%" height="450" frameborder="0" src="https://links.collect.chat/669e9e5a3e99425e992deaa9" class="lazyloaded" data-load-mode="1"></iframe>
              <script async type="text/javascript" src="https://collectcdn.com/embed.js"></script>
            `,
          }}
        />
      </div>
    </div>
  );
};

export default BotComponent;
