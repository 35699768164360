import { motion, useTransform, useScroll } from 'framer-motion';
import { useRef } from 'react';


const Experience = () => {
  return (
    <div className='bg-neutral-800'>
      <HorizontalScrollCarousel />
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  // Transform the scroll position to horizontal movement
  const x = useTransform(scrollYProgress, [0, 1], ['-400%', '70%']);

  return (
    <section
      ref={targetRef}
      className='bg-white mt-12 relative h-[20vh] overflow-hidden'
    >
      <div className='sticky top-0 left-0 overflow-visible'>
        <motion.div
          style={{ x }}
          className='relative flex justify-center items-center h-full'
        >
          <CallToAction />
        </motion.div>
      </div>
    </section>
  );
};

const CallToAction = () => (
  <div className='p-4'>
    <p className='text-center text-black flex text-7xl'>
      Still<span className='name'> {'  '}Here?</span>
    </p>
  </div>
);

export default Experience;
