import React from 'react';
import MongoDB from '../../assets/images/MongoDBCertification.png';
import NodeJS from '../../assets/images/NodeJSCertification.png';
import Postman from '../../assets/images/PostmanCertification.png';
import NestJS from '../../assets/images/NestJSCertification.png';
import { FaArrowRight } from 'react-icons/fa'; // Importing right arrow icon from react-icons

const Certifications = ({ id }) => {
  return (
    <div id={id} className='p-4 my-10'>
      <p className='pt-0 mx-4 flex justify-center text-black text-4xl mb-10 lg:text-5xl py-4'>
        My<span className='name'> {'  '}Certifications</span>
      </p>
      <div className='grid gap-4 mt-4 grid-cols-2 lg:grid-cols-4'>
        <Card
          title='Postman API Fundamentals'
          subtitle='Certificate'
          href='https://badgr.com/public/assertions/NHtVCgDtSuGJaz3ANq8TXg?identity__email=pahwabharat15@gmail.com' // Example link
          Icon={Postman}
        />

        <Card
          title='MongoDB Developer Path'
          subtitle='Certificate'
          href='https://learn.mongodb.com/c/iKRf4MUyRD-mT32sqB_tcQ' // Example link
          Icon={MongoDB}
        />
        <Card
          title='Node.js, Express, MongoDB'
          subtitle='Certificate'
          href='https://drive.google.com/file/d/1rx_MEldkBzJXDHxjNZwPjl52BiOvukS2/view?usp=drive_link' // Example link
          Icon={NodeJS}
        />

        <Card
          title='NestJS: Developer Guide '
          subtitle='Certificate'
          href='https://udemy-certificate.s3.amazonaws.com/pdf/UC-058b9afa-e57d-40a9-94ba-0cac09b9a019.pdf' // Example link
          Icon={NestJS}
        />
      </div>
    </div>
  );
};

const Card = ({ title, subtitle, Icon, href }) => {
  return (
    <div className='w-full p-4 rounded-md border-[1px] border-slate-300 relative overflow-hidden group bg-white'>
      <div className='absolute inset-0 bg-gradient-to-r from-zinc-600 to-neutral-600 translate-y-[100%] group-hover:translate-y-[0%] transition-transform duration-300' />
      <img
        src={Icon}
        alt={title}
        className='absolute mt-6 w-60 m-auto left-[-30px] h-18 flex self-center justify-center z-10 -top-12 -right-12 text-4xl text-slate-100 group-hover:text-violet-400 group-hover:rotate-12 transition-transform duration-300'
      />
      <p className='font-medium pt-12 text-[1rem] lg:text-lg text-slate-950 group-hover:text-white relative z-10 duration-300'>
        {title}
      </p>
      <p className='text-slate-400 pt-4 relative z-10 duration-300'>
        <a
          href={href}
          className='flex items-center text-slate-400 group-hover:text-white transition-colors duration-300'
          target='_blank'
          rel='noopener noreferrer'
        >
          {subtitle}
          <FaArrowRight className='ml-2 text-slate-400 group-hover:text-white transition-transform duration-300' />
        </a>
      </p>
    </div>
  );
};

export default Certifications;
