import React from 'react';
import {
  SiReact,
  SiJavascript,
  SiCss3,
  SiHtml5,
  SiFigma,
  SiCanva,
  SiVercel,
  SiNetlify,
  SiFirebase,
  SiGoogleanalytics,
} from 'react-icons/si';
import { DiGit, DiMongodb } from 'react-icons/di';
import { FaGithub } from 'react-icons/fa';
import { useAnimate } from 'framer-motion';
import TailwindCSSImage from '../../assets/images/TailwindCSS.png';
import Docker from '../../assets/images/Docker.jpg';
import SQL from '../../assets/images/SQL.png';
import NodeJS from '../../assets/images/NodeJS.png';
import ImageMotion from '../common/ImageMotion';
import Weekend from '../../assets/images/Weekend.jpg';

// Define the TechStack component
const TechStack = () => {
  return (
    <div className='bg-neutral-50 px-4 py-12'>
      <p className='text-4xl text-black py-4 px-2 flex justify-center m-auto'>
        Tech <span className='name'> Stack</span>
      </p>
      <ImageMotion ImageSrc={Weekend} />
      <div className='mx-auto max-w-7xl'>
        <ClipPathLinks />
      </div>
      <p className='text-4xl text-black py-4 px-2 flex justify-center m-auto'>
        UI/ <span className='name'> UX</span>
      </p>
      <div className='mx-auto max-w-7xl'>
        <ClipPathDesignLinks />
      </div>
    </div>
  );
};

// Define the ClipPathLinks component
const ClipPathLinks = () => {
  return (
    <>
      <div className='divide-y divide-neutral-900 border border-neutral-900'>
        <div className='grid grid-cols-3'>
          <LinkBox Icon={SiHtml5} />
          <LinkBox Icon={SiCss3} />
          <LinkBox Icon={SiJavascript} />
        </div>
        <div className='grid grid-cols-4'>
          <LinkBox Icon={SiReact} />
          <img
            className='h-[4.5rem] md:lg:h-24 p-6 m-auto'
            src={TailwindCSSImage}
            alt='TailwindCSS'
          ></img>
          <LinkBox Icon={DiGit} />
          <LinkBox Icon={FaGithub} />
        </div>
        <div className='grid grid-cols-4'>
          <img
            className='h-[5rem] md:lg:h-24 p-6 m-auto'
            alt='NodeJS'
            src={NodeJS}
          ></img>
          <LinkBox Icon={DiMongodb} />
          <LinkBox Icon={SiFirebase} />
          <img
            className='h-[5.5rem] md:lg:h-24 p-6 m-auto'
            src={SQL}
            alt='SQL'
          ></img>
        </div>
        <div className='grid grid-cols-4'>
          <img
            className='h-[5rem] md:lg:h-24 p-6 m-auto'
            src={Docker}
            alt='Docker'
          ></img>
          <LinkBox Icon={SiNetlify} />
          <LinkBox Icon={SiVercel} />
          <LinkBox Icon={SiGoogleanalytics} />
        </div>
      </div>
    </>
  );
};

const ClipPathDesignLinks = () => {
  return (
    <div className='divide-y divide-neutral-900 border border-neutral-900'>
      <div className='grid grid-cols-2 divide-x divide-neutral-900'>
        <LinkBox Icon={SiFigma} />
        <LinkBox Icon={SiCanva} />
      </div>
    </div>
  );
};

const NO_CLIP = 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)';
const BOTTOM_RIGHT_CLIP = 'polygon(0 0, 100% 0, 0 0, 0% 100%)';
const TOP_RIGHT_CLIP = 'polygon(0 0, 0 100%, 100% 100%, 0% 100%)';
const BOTTOM_LEFT_CLIP = 'polygon(100% 100%, 100% 0, 100% 100%, 0 100%)';
const TOP_LEFT_CLIP = 'polygon(0 0, 100% 0, 100% 100%, 100% 0)';

const ENTRANCE_KEYFRAMES = {
  left: [BOTTOM_RIGHT_CLIP, NO_CLIP],
  bottom: [BOTTOM_RIGHT_CLIP, NO_CLIP],
  top: [BOTTOM_RIGHT_CLIP, NO_CLIP],
  right: [TOP_LEFT_CLIP, NO_CLIP],
};

const EXIT_KEYFRAMES = {
  left: [NO_CLIP, TOP_RIGHT_CLIP],
  bottom: [NO_CLIP, TOP_RIGHT_CLIP],
  top: [NO_CLIP, TOP_RIGHT_CLIP],
  right: [NO_CLIP, BOTTOM_LEFT_CLIP],
};

// Define the LinkBox component
const LinkBox = ({ Icon, href }) => {
  const [scope, animate] = useAnimate();

  const getNearestSide = (e) => {
    const box = e.target.getBoundingClientRect();

    const proximityToLeft = {
      proximity: Math.abs(box.left - e.clientX),
      side: 'left',
    };
    const proximityToRight = {
      proximity: Math.abs(box.right - e.clientX),
      side: 'right',
    };
    const proximityToTop = {
      proximity: Math.abs(box.top - e.clientY),
      side: 'top',
    };
    const proximityToBottom = {
      proximity: Math.abs(box.bottom - e.clientY),
      side: 'bottom',
    };

    const sortedProximity = [
      proximityToLeft,
      proximityToRight,
      proximityToTop,
      proximityToBottom,
    ].sort((a, b) => a.proximity - b.proximity);

    return sortedProximity[0].side;
  };

  const handleMouseEnter = (e) => {
    const side = getNearestSide(e);

    animate(scope.current, {
      clipPath: ENTRANCE_KEYFRAMES[side],
    });
  };

  const handleMouseLeave = (e) => {
    const side = getNearestSide(e);

    animate(scope.current, {
      clipPath: EXIT_KEYFRAMES[side],
    });
  };

  return (
    <p
      onMouseEnter={(e) => {
        handleMouseEnter(e);
      }}
      onMouseLeave={(e) => {
        handleMouseLeave(e);
      }}
      className='relative grid h-24 w-full place-content-center sm:h-36 md:h-48'
    >
      <Icon className='text-4xl text-black sm:text-4xl md:text-7xl' />

      <div
        ref={scope}
        style={{
          clipPath: BOTTOM_RIGHT_CLIP,
        }}
        className='absolute inset-0 grid place-content-center bg-neutral-900 text-white'
      >
        <Icon className='text-3xl sm:text-4xl md:text-5xl' />
      </div>
    </p>
  );
};

export default TechStack;
