import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const AnimatedText = ({ lines }) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (index === lines.length) {
      setIndex(0);
    }
  }, [index, lines.length]);

  useEffect(() => {
    if (subIndex === lines[index].length + 1 && !reverse) {
      setTimeout(() => setReverse(true)); // pause at the end
      return;
    }

    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prevIndex) => (prevIndex + 1) % lines.length);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prevSubIndex) => prevSubIndex + (reverse ? -1 : 1));
    }, Math.max(reverse ? 50 : subIndex === lines[index].length ? 1000 : 50, 15));

    return () => clearTimeout(timeout);
  }, [subIndex, reverse, index, lines]);

  return (
    <div className='text-2xl liners mt-4 text-black h-8 w-full relative overflow-hidden'>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.1 }}
        className='absolute w-full text-base sm:text-base lg:text-xl'
      >
        {`${lines[index].substring(0, subIndex)}${
          subIndex === lines[index].length ? '' : '|'
        }`}
      </motion.div>
    </div>
  );
};

export default AnimatedText;
