import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FaArrowRight, FaMedal } from 'react-icons/fa';
import Confetti from 'react-confetti';
import useWindowSize from './UseWindowSize';
import AchievementsShuffle from './AchievementsShuffle';

const Achievements = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const [achievementTitle, setAchievementTitle] = useState(null);
  const { width, height } = useWindowSize();
  const achievementsRef = useRef(null);

  const achievementsData = [
    {
      title: 'Winner',
      subtitle: 'View',
      href: 'https://drive.google.com/file/d/1k7A4md7kCYUj4ZeA0y4tVVSLByo4L9QX/view?usp=drivesdk',
      description: 'UXplore Contest organised by IOSD MAIT',
      linkType: 'certificate',
      icon: 1,
    },
    {
      title: 'Winner',
      subtitle: 'View',
      href: 'https://www.figma.com/design/CuP5RSLdsyPOkvanufbDjo/Cosmo-Canvas?node-id=0-1&t=zXVlmh3CIRofsvGx-1',
      description: 'Cosmic Canvas organised by Tech Vision Alliance MAIT',
      linkType: 'Figma File',
      icon: 2,
    },
    {
      title: 'Winner',
      subtitle: 'View',
      href: 'https://drive.google.com/file/d/1EqZMPgS3mx4_XBaIVhNvFeVYK8J6N5Nh/view?usp=drivesdk',
      description: 'Power Point Design Contest',
      linkType: 'Result',
      icon: 3,
    },
    {
      title: '2nd Place',
      subtitle: 'View',
      href: 'https://drive.google.com/file/d/19FbJgN5tLT-jT84PsMpqpBAUFU6kQupI/view?usp=drivesdk',
      description: 'Design Replica organised by TechCom MAIT',
      linkType: 'certificate',
      icon: 4,
    },
    {
      title: '2nd Place',
      subtitle: 'View',
      href: 'https://drive.google.com/file/d/1HJyGY6L2OCDL9QCblGJFjXYTAcxza_Op/view?usp=drive_link',
      description: 'Web-ION, organised by TechCom MAIT',
      linkType: 'certificate',
      icon: 5,
    },
    {
      title: '2nd RunnerUp',
      subtitle: 'View',
      href: 'https://drive.google.com/file/d/1KXvw558JOCFujZC-J9W_xCWXdm-nG0vo/view?usp=drive_link',
      description:
        'Second Runner Up | Techstars Startup Weekend organised by E-Cell, DTU powered by Google for Startups.',
      linkType: 'certificate',
      icon: 6,
    },
    {
      title: 'Special Prize',
      subtitle: 'View',
      href: 'https://www.linkedin.com/posts/bharat-pahwa_mait-hackathon-activity-7051591350332399617-4Su1',
      description: 'Intra College MAIT Hackathon',
      linkType: 'LinkedIn Post',
      icon: 7,
    },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
    } else {
      document.body.style.overflow = ''; // Allow scrolling when modal is closed
    }
  }, [isOpen]);

  const handleViewClick = (achievement, linkType, title, href) => {
    setSelectedAchievement({ achievement, linkType, href });
    setAchievementTitle(title);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div id={id} className='p-4 my-10' ref={achievementsRef}>
      <p className='pt-0 mx-4 flex justify-center text-black text-4xl mb-10 lg:text-5xl py-4'>
        My<span className='name'> {'  '}Achievements</span>
      </p>
      {/* <AchievementsShuffle /> */}
      <div className='grid gap-4 mt-4 grid-cols-2 justify-center lg:grid-cols-4'>
        {achievementsData.map((achievement, index) => (
          <Card
            key={index}
            title={achievement.title}
            subtitle={achievement.subtitle}
            href={achievement.href}
            Icon={achievement.icon}
            onClick={() =>
              handleViewClick(
                achievement.description,
                achievement.linkType,
                achievement.title,
                achievement.href
              )
            }
          />
        ))}
        {/* Add more Card components as needed */}
        <div className='w-full p-4 rounded-md border-[1px] border-slate-300 relative overflow-hidden group bg-white cursor-pointer'>
          <div className='absolute inset-0 bg-gradient-to-r from-zinc-900 to-neutral-700 translate-y-[0%] transition-transform duration-300' />
          <p className='absolute mt-12 w-60 m-auto left-[-11rem] md:lg:left-[-22rem] text:6xl font-bold h-18 flex self-center justify-center z-10 -top-12 -right-12 text-6xl text-blue-500 group-hover:text-violet-400 rotate-12 transition-transform duration-300'>
            {8}
          </p>
          <p className='font-medium pt-12 text-[1rem] lg:text-xl text-white group-hover:text-white relative z-10 duration-300 text-center'>
            Coming soon...
          </p>
        </div>
      </div>
      <SpringModal
        isOpen={isOpen}
        closeModal={closeModal}
        achievementDetails={selectedAchievement}
        achievementTitle={achievementTitle}
        width={width}
        height={height}
        confettiTop={
          achievementsRef.current ? achievementsRef.current.offsetTop : 0
        }
      />
    </div>
  );
};

const Card = ({ title, subtitle, Icon, href, onClick }) => {
  return (
    <div
      className='w-full p-4 rounded-md border-[1px] border-slate-300 relative overflow-hidden group bg-white cursor-pointer'
      onClick={onClick}
    >
      <div className='absolute inset-0 bg-gradient-to-r from-zinc-900 to-neutral-700 translate-y-[0%] transition-transform duration-300' />
      <p className='absolute mt-12 w-60 m-auto left-[-11rem] md:lg:left-[-22rem] text:6xl font-bold h-18 flex self-center justify-center z-10 -top-12 -right-12 text-6xl text-blue-500 group-hover:text-violet-400 rotate-12 transition-transform duration-300'>
        {Icon}
      </p>
      <p className='font-medium pt-12 text-[1rem] lg:text-lg text-white group-hover:text-white relative z-10 duration-300'>
        {title}
      </p>
      <p className='text-slate-400 pt-4 relative z-10 duration-300'>
        <span className='flex items-center text-slate-300 group-hover:text-white transition-colors duration-300'>
          {subtitle}
          <FaArrowRight className='ml-2 text-slate-400 group-hover:text-white transition-transform duration-300' />
        </span>
      </p>
    </div>
  );
};

const SpringModal = ({
  isOpen,
  closeModal,
  achievementDetails,
  achievementTitle,
  width,
  height,
  confettiTop,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
            className='bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-40 grid place-items-center cursor-pointer'
          >
            <motion.div
              initial={{ scale: 0, rotate: '12.5deg' }}
              animate={{ scale: 1, rotate: '0deg' }}
              exit={{ scale: 0, rotate: '0deg' }}
              onClick={(e) => e.stopPropagation()}
              className='bg-gradient-to-br from-violet-600 to-indigo-600  text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden'
            >
              <FaMedal className='text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24' />
              <div className='relative z-10'>
                <div className='bg-white w-16 h-16 mb-2 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto'>
                  <FaMedal />
                </div>
                <p className='text-xl text-center mb-2'>{achievementTitle}</p>
                <p className='text-center font-bold mb-6'>
                  {achievementDetails.achievement}
                </p>
                <div className='flex gap-2'>
                  <button
                    onClick={closeModal}
                    className='bg-transparent hover:bg-white/10 transition-colors text-sm lg:text-base text-white font-semibold w-full py-2 rounded'
                  >
                    Go back
                  </button>
                  <a
                    href={achievementDetails.href}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='bg-white hover:opacity-90 transition-opacity text-indigo-600 text-sm lg:text-base font-semibold w-full py-2 rounded text-center'
                  >
                    {achievementDetails?.linkType === 'certificate'
                      ? 'Certificate Link'
                      : achievementDetails.linkType}
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
          <Confetti
            width={width}
            height={height}
            style={{
              position: 'absolute',
              top: confettiTop,
              left: 0,
              zIndex: 45, // Ensure confetti appears in front of the blur
              pointerEvents: 'none', // Ensure confetti does not block clicks
            }}
            numberOfPieces={100}
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default Achievements;
