import React from 'react';
import AnimatedImages from './AnimatedImages';
import Hero from './Hero';
import ResumeSection from './ResumeSection';
import rocket from '../../assets/images/rocket.png';
import smilingRobot from '../../assets/images/smilingRobot.png';
import API from '../../assets/images/API.png';
import code3d from '../../assets/images/code3d.png';

const leftImages = [
  { src: rocket, alt: 'rocket', className: 'rocket', height: '8rem' },
  {
    src: smilingRobot,
    alt: 'Smiling Robot',
    className: 'smilingRobot',
    height: '11rem',
  },
];

const rightImages = [
  { src: API, alt: 'API', className: 'API', height: '10rem' },
  { src: code3d, alt: 'Code 3D', className: 'code3d', height: '7rem' },
];

const HeroSection = ({ id }) => {
  return (
    <>
      <div
        id={id}
        className='relative flex flex-col h-[69vh] lg:min-h-[110vh] mt-0'
      >
        <div className='relative flex-grow'>
          <div className='absolute inset-0 overflow-hidden items-center'>
            {/* Render AnimatedImages only on tablets and desktops */}
            <div className='desktop-only'>
              <AnimatedImages side='left' images={leftImages} />
            </div>
            <div className='flex flex-col w-full mt-10 sm:mt-14 justify-center'>
              <Hero />
              <ResumeSection />
            </div>
            <div className='desktop-only'>
              <AnimatedImages side='right' images={rightImages} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
