import { useMotionValue, motion, useSpring, useTransform } from 'framer-motion';
import React, { useRef } from 'react';
import MAIT from '../../assets/images/MAIT.jpg';
import RPS from '../../assets/images/RPS.jpg';
import Apeejay from '../../assets/images/Apeejay.jpg';

const HoverImageLinks = () => {
  return (
    <section className='p-4 md:p-8'>
      <div className='mx-auto'>
        <Link
          title='B.Tech (Information Technology)'
          heading='Maharaja Agrasen Institute of Technology, New Delhi'
          subheading='8.9 CGPA ,  [2021 - 2025]'
          imgSrc={MAIT}
        />
        <Link
          title='Class XII'
          heading='RPS School, Mohindergarh'
          subheading='95% '
          imgSrc={RPS}
        />{' '}
        <Link
          title='Class X'
          heading='Apeejay School, Charkhi Dadri'
          subheading='89.4% '
          imgSrc={Apeejay}
        />
      </div>
    </section>
  );
};

const Link = ({ title, heading, imgSrc, subheading, href }) => {
  const ref = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const top = useTransform(mouseYSpring, [0.5, -0.5], ['40%', '60%']);
  const left = useTransform(mouseXSpring, [0.5, -0.5], ['60%', '70%']);

  const handleMouseMove = (e) => {
    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };

  return (
    <motion.a
      href={href}
      ref={ref}
      onMouseMove={handleMouseMove}
      initial='initial'
      whileHover='whileHover'
      className='group relative flex items-center cursor-default justify-between border-b-2 border-neutral-700 py-2 transition-colors duration-500 hover:border-neutral-50 md:py-8'
    >
      <div>
        <span className='relative z-10 mt-2 block text-[1rem] lg:text-2xl mb-2 font-bold text-neutral-200 transition-colors duration-500 group-hover:text-neutral-50'>
          {title}
        </span>
        <motion.span
          variants={{
            initial: { x: 0 },
            whileHover: { x: -16 },
          }}
          transition={{
            type: 'spring',
            staggerChildren: 0.075,
            delayChildren: 0.25,
          }}
          className='relative z-10 block text-xl lg:text-3xl font-semibold text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50 md:text-6xl'
        >
          {heading}
        </motion.span>
        <span className='relative z-10 mt-2 block text-base text-neutral-100 transition-colors duration-500 group-hover:text-neutral-50'>
          {subheading}
        </span>
      </div>

      <motion.img
        style={{
          top,
          left,
          translateX: '-50%',
          translateY: '-50%',
        }}
        variants={{
          initial: { scale: 0, rotate: '-12.5deg' },
          whileHover: { scale: 1, rotate: '12.5deg' },
        }}
        transition={{ type: 'spring' }}
        src={imgSrc}
        className='absolute z-0 h-24 w-32 rounded-lg object-cover md:h-48 md:w-64'
        alt={`Image representing a link for ${heading}`}
      />

      <motion.div
        variants={{
          initial: {
            x: '25%',
            opacity: 0,
          },
          whileHover: {
            x: '0%',
            opacity: 1,
          },
        }}
        transition={{ type: 'spring' }}
        className='relative z-10 p-4'
      ></motion.div>
    </motion.a>
  );
};

export default HoverImageLinks;
