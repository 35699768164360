import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { twMerge } from 'tailwind-merge';
import ByteBites from '../../assets/images/ByteBites.png';
import FlexFit from '../../assets/images/FlexFit.png';
import HostelGenix from '../../assets/images/HostelGenix.png';
import EngageGPT from '../../assets/images/EngageGPT.png';
import SkillOMeter from '../../assets/images/SkillOMeter.png';
import JournalsDesign from '../../assets/images/JournalsDesign.png';

const SpringModal = ({
  isOpen,
  setIsOpen,
  title,
  description,
  file,
  prototype,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className='bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer'
        >
          <motion.div
            initial={{ scale: 0, rotate: '12.5deg' }}
            animate={{ scale: 1, rotate: '0deg' }}
            exit={{ scale: 0, rotate: '0deg' }}
            onClick={(e) => e.stopPropagation()}
            className='bg-gradient-to-br from-violet-600 to-indigo-600 text-white p-6 rounded-lg w-full max-w-xl shadow-xl cursor-default relative overflow-hidden'
          >
            <div className='relative z-10 '>
              <h3 className=' text-2xl lg:text-3xl font-bold text-center mb-2'>
                {title}
              </h3>
              <p className='text-center mb-4 mt-2'>{description}</p>
              <div className='flex justify-between items-center mb-4'>
                <a
                  href={file}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded text-center mx-2'
                >
                  Figma FIle
                </a>
                <a
                  href={prototype}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded text-center mx-2'
                >
                  Prototype
                </a>
              </div>
              <button
                onClick={() => setIsOpen(false)}
                className='bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded'
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Card = ({
  containerRef,
  src,
  alt,
  top,
  left,
  rotate,
  className,
  onClick,
}) => {
  const [zIndex, setZIndex] = useState(0);

  const updateZIndex = () => {
    const els = document.querySelectorAll('.drag-elements');

    let maxZIndex = -Infinity;

    els.forEach((el) => {
      let zIndex = parseInt(
        window.getComputedStyle(el).getPropertyValue('z-index')
      );

      if (!isNaN(zIndex) && zIndex > maxZIndex) {
        maxZIndex = zIndex;
      }
    });

    setZIndex(maxZIndex + 1);
  };

  return (
    <motion.img
      onMouseDown={updateZIndex}
      onClick={onClick}
      style={{
        top,
        left,
        rotate,
        zIndex,
      }}
      className={twMerge(
        'drag-elements absolute w-64 h-72 bg-neutral-200 p-1 pb-4 cursor-pointer',
        className
      )}
      src={src}
      alt={alt}
      drag
      dragConstraints={containerRef}
      dragElastic={0.65}
    />
  );
};

const ProjectHighlightsDesign = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const containerRef = useRef(null);

  const handleCardClick = (details) => {
    setProjectDetails(details);
    setIsOpen(true);
  };
  return (
    <section className='relative grid h-[103vh] w-full place-content-center overflow-hidden bg-neutral-950'>
      <h2 className='relative z-0 text-[20vw] font-black text-neutral-800 md:text-[200px]'>
        UI/UX Designing<span className='text-indigo-500'>.</span>
      </h2>
      <div className='absolute inset-0 z-10' ref={containerRef}>
        <Card
          containerRef={containerRef}
          src={FlexFit}
          alt='FlexFit'
          rotate='4deg'
          top='60%'
          left='58%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Health Management App Design',
              description:
                'Project made in a contest organised my IOSD MAIT (Winner)',
              file: 'https://www.figma.com/design/TR9KnVIHBTvASLXYigJe3U/UXPLORE?node-id=0-1&t=AFDeeDRWS203WJMf-1',
              prototype:
                'https://www.figma.com/proto/TR9KnVIHBTvASLXYigJe3U/UXPLORE?page-id=0%3A1&node-id=2-2&viewport=386%2C350%2C0.14&t=D5OgEuIJUPqDAoFa-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=2%3A2',
            })
          }
        />{' '}
        <Card
          containerRef={containerRef}
          src={JournalsDesign}
          alt='Journals'
          rotate='6deg'
          top='5%'
          left='45%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Journals Website',
              description:
                'Designed as a part of a project assigned by college Professors',
              file: 'https://www.figma.com/design/0jn4XO77qjg5GsU4sMN8l0/Journals-Design-MAIT?node-id=0-1&t=wrujO0eMOxxxQZKe-1',
              prototype:
                'https://www.figma.com/proto/0jn4XO77qjg5GsU4sMN8l0/Journals-Design-MAIT?page-id=0%3A1&node-id=1-11&viewport=630%2C333%2C0.07&t=IxgVaEg6k1LNtF6J-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A1268',
            })
          }
        />
        <Card
          containerRef={containerRef}
          src={HostelGenix}
          alt='Hostel Genix'
          rotate='12deg'
          top='35%'
          left='50%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Hostel Management App Design',
              description:
                'Designed as a part of UI/UX Competition organised bt IEEE Manipal',
              file: 'https://www.figma.com/design/hsaL9sBJdWPpfcxYTLvzmk/Designathon-IEEE-Manipal?node-id=0-1&t=qkAVNE1JmPAKmv9r-1s',
              prototype:
                'https://www.figma.com/proto/hsaL9sBJdWPpfcxYTLvzmk/Designathon-IEEE-Manipal?page-id=0%3A1&node-id=19-1678&viewport=560%2C157%2C0.15&t=iLsipQWxt0cMzG3a-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=1%3A2&show-proto-sidebar=1',
            })
          }
        />
        <Card
          containerRef={containerRef}
          src={ByteBites}
          alt='Byte Bites'
          rotate='-6deg'
          top='40%'
          left='10%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Byte Bites',
              description:
                'Designed as a part of UI/UX Designathon organised by GGSIPU',
              file: 'https://www.figma.com/design/V1DsdZWnLaTlpHdIhy0Qq2/ByteBites?node-id=0-1&t=UQYVpo7A5qcfUrtF-1',
              prototype:
                'https://www.figma.com/proto/V1DsdZWnLaTlpHdIhy0Qq2/ByteBites?page-id=0%3A1&node-id=60-166&viewport=-227%2C224%2C0.18&t=5WdgpodDKu0ixxjy-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=60%3A149',
            })
          }
        />
        <Card
          containerRef={containerRef}
          src={EngageGPT}
          alt='EngageGPT'
          rotate='-18deg'
          top='15%'
          left='10%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'EngageGPT - AI for LinkedIn',
              description: 'A Chrome extension with many amazing features',
              file: 'https://www.figma.com/design/u14OfK7eMAIxufI2b3IDcQ/EngageGPT?node-id=0-1&t=qonFxdIV2MOkPQ8q-1',
              prototype:
                'https://www.figma.com/proto/u14OfK7eMAIxufI2b3IDcQ/EngageGPT?page-id=0%3A1&node-id=39-1093&viewport=-775%2C-444%2C0.1&t=mFVbYAjHMZQNFvBm-1&scaling=min-zoom&content-scaling=fixed',
            })
          }
        />{' '}
        <Card
          containerRef={containerRef}
          src={SkillOMeter}
          alt='SkillOmeter'
          rotate='-18deg'
          top='60%'
          left='20%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Skill-O-Meter',
              description:
                'A Skill Analysis Website for people in various domains ',
              file: 'https://www.figma.com/design/nOzOycmYyPH0h0G5yhBi1G/Skil-O-Meter?node-id=0-1&t=u1vQyscI29bs8pp3-1',
              prototype:
                'https://www.figma.com/proto/nOzOycmYyPH0h0G5yhBi1G/Skil-O-Meter?page-id=0%3A1&node-id=24-2&viewport=918%2C132%2C0.2&t=ioQXigfQDsv3n8HR-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=2%3A2',
            })
          }
        />{' '}
      </div>

      <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} {...projectDetails} />
    </section>
  );
};

export default ProjectHighlightsDesign;
