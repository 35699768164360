import React from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { FiArrowRight, FiClock, FiMapPin } from 'react-icons/fi';
import HeebloLogo from '../../assets/images/HeebloLogo.jpg';

const Project = () => {
  return (
    <>
      <div className='w-[120vw] md:xl:lg:w-[80vw] bg-zinc-950 px-4 py-6 lg:py-8 text-zinc-50'>
        <motion.div
          initial='initial'
          animate='animate'
          transition={{
            staggerChildren: 0.05,
          }}
          className='mx-auto grid max-w-4xl grid-flow-row grid-cols-12 gap-3'
        >
          <HeaderBlock />
          <AboutBlock />
          <LinkBlock />
          <TimeBlock />
          <LocationBlock />
        </motion.div>
      </div>
    </>
  );
};

const Block = ({ className, ...rest }) => {
  return (
    <motion.div
      variants={{
        initial: {
          scale: 0.5,
          y: 50,
          opacity: 0,
        },
        animate: {
          scale: 1,
          y: 0,
          opacity: 1,
        },
      }}
      transition={{
        type: 'spring',
        mass: 3,
        stiffness: 400,
        damping: 50,
      }}
      className={twMerge(
        'col-span-4 rounded-lg border border-zinc-700 bg-zinc-800 p-6',
        className
      )}
      {...rest}
    />
  );
};

const HeaderBlock = () => (
  <Block className='col-span-12 row-span-2 md:col-span-6'>
    <div className='flex gap-4 justify-start items-center  mb-4'>
      <img src={HeebloLogo} alt='avatar' className='mb-0 size-14 rounded-md' />
      <p className='text-xl text-white font-bold'>
        Heeblo{' '}
        <p className='text-sm mt-2 text-zinc-300'>Aug 2023 - Sept 2023</p>
      </p>
    </div>
    <h1 className='mt-6  text-white text-2xl lg:text-3xl font-medium leading-tight'>
      UI/UX Designer{' '}
    </h1>
  </Block>
);

const LinkBlock = () => {
  return (
    <div className='bg-zinc-800 rounded-md border col-span-12 border-zinc-700 flex flex-wrap items-center gap-2 p-4 md:col-span-6'>
      <div className='flex gap-4 justify-between w-full m-auto items-center'>
        <a
          href='https://drive.google.com/file/d/1OMcdaevpx7ogwku-T3xy_bxKg9pOzgOQ/view?usp=drive_link'
          target='_blank'
          rel='noreferrer'
          className='flex btn  bg-zinc-700 rounded-full hover:shadow-2xl text-sm lg:text-lg py-2 px-4 items-center gap-1 border-2 border-zinc-800 text-red-300 hover:bg-zinc-800 hover:border-2 hover:border-red-300'
        >
          Certificate <FiArrowRight className='arrow' />
        </a>{' '}
        <a
          href='https://www.figma.com/design/ohrez85qOgqzPJwGeVZRuT/Heeblo-Design?node-id=19-219&t=IzUSzIpSuJEJp95Y-1'
          target='_blank'
          rel='noreferrer'
          className='flex btn bg-zinc-700 rounded-full hover:shadow-2xl text-sm lg:text-lg py-2 px-4 items-center gap-1 border-2 border-zinc-800 text-red-300 hover:bg-zinc-800 hover:border-2 hover:border-red-300'
        >
          Design Work
          <FiArrowRight className='arrow' />
        </a>
      </div>
    </div>
  );
};

const AboutBlock = () => (
  <Block className='col-span-12 text-2xl leading-snug'>
    <p>
      <p className='text-zinc-400 text-base lg:text-lg'>
        • <span className='font-semibold  text-white'>Successfully</span>{' '}
        created 15+ intutive UI designs for multiple screens in a month
      </p>
      {<br></br>}
      <p className='text-base lg:text-lg text-zinc-400'>
        • Done wireframing, mockups, prototyping to{' '}
        <span className='font-semibold text-white'>
          understand the user experience.
        </span>
      </p>
      {<br></br>}
      <p className='text-base lg:text-lg text-zinc-400'>
        • <span className='font-semibold text-white'>Analyzed </span> various
        requirements to ensure project was delivered to the{' '}
        <span className='font-bold text-white'>highest standards.</span>
      </p>
    </p>
  </Block>
);

const LocationBlock = () => (
  <Block className='flex col-span-6 items-center gap-4 md:col-span-3'>
    <FiMapPin className='text-3xl' />
    <p className='text-sm lg:text-lg text-center text-zinc-400'>Remote</p>
  </Block>
);

const TimeBlock = () => (
  <Block className='flex col-span-6 items-center gap-4 md:col-span-3'>
    <FiClock className='text-3xl' />
    <p className='text-sm lg:text-lg text-center text-zinc-400'>1 Month</p>
  </Block>
);

export default Project;
