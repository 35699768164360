import React from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { FiArrowRight, FiClock, FiMapPin } from 'react-icons/fi';
import LBTLogo from '../../assets/images/LBTLogo.png';

const Project = () => {
  return (
    <>
      <div className='w-[120vw] md:xl:lg:w-[80vw] bg-zinc-950 px-4 py-6 lg:py-8 text-zinc-50'>
        <motion.div
          initial='initial'
          animate='animate'
          transition={{
            staggerChildren: 0.05,
          }}
          className='mx-auto grid max-w-4xl grid-flow-row grid-cols-10 gap-3'
        >
          <HeaderBlock />
          <LinkBlock />
          <SocialBlock />
          <AboutBlock />
          <TimeBlock />
          <LocationBlock />
        </motion.div>
      </div>
    </>
  );
};

const Block = ({ className, ...rest }) => {
  return (
    <motion.div
      variants={{
        initial: {
          scale: 0.5,
          y: 50,
          opacity: 0,
        },
        animate: {
          scale: 1,
          y: 0,
          opacity: 1,
        },
      }}
      transition={{
        type: 'spring',
        mass: 3,
        stiffness: 400,
        damping: 50,
      }}
      className={twMerge(
        'col-span-4 rounded-lg border border-zinc-700 bg-zinc-800 p-6',
        className
      )}
      {...rest}
    />
  );
};

const HeaderBlock = () => (
  <Block className='col-span-10 row-span-2 md:col-span-5'>
    <div className='flex gap-4 justify-start items-center  mb-4'>
      <img src={LBTLogo} alt='avatar' className='mb-0 size-14 rounded-md' />
      <p className='text-xl font-bold text-white'>
        Let's Bunk Today{' '}
        <p className='text-sm mt-2 text-zinc-300'>Nov 2022 - Present</p>
      </p>
    </div>
    <h1 className='mt-6 text-2xl lg:text-2xl text-white font-medium leading-tight'>
      Founder & Community Manager{' '}
    </h1>
  </Block>
);

const LinkBlock = () => {
  return (
    <div className='bg-zinc-800 rounded-md border col-span-10 border-zinc-700 flex flex-wrap items-center gap-2 p-3 md:col-span-5'>
      <div className='flex gap-4 justify-center m-auto items-center'>
        <a
          href='https://letsbunktoday.tech'
          target='_blank'
          rel='noreferrer'
          className='flex btn  bg-zinc-700 hover:shadow-xl rounded-full py-2 px-4 text-sm lg:text-lg items-center gap-1 border-2 border-zinc-800 text-red-200 hover:bg-zinc-800 hover:border-2 hover:border-red-300'
        >
          Website <FiArrowRight className='arrow' />
        </a>{' '}
        <a
          href='https://chat.whatsapp.com/ERbwoQH9JqZG4l52KC8feU'
          target='_blank'
          rel='noreferrer'
          className='flex btn bg-zinc-700 hover:shadow-xl rounded-full py-2 text-sm lg:text-lg px-4 items-center gap-1 border-2 border-zinc-800 text-red-200 hover:bg-zinc-800 hover:border-2 hover:border-red-300'
        >
          Join Community <FiArrowRight className='arrow' />
        </a>
      </div>
    </div>
  );
};
const SocialBlock = () => {
  return (
    <div className='bg-zinc-800 rounded-md border col-span-10 border-zinc-700 flex flex-wrap items-center gap-2 p-3 md:col-span-5'>
      <p className='desktop-only text-white'>Follow us on </p>
      <div className='flex gap-4 justify-center m-auto items-center'>
        <a
          href='https://linkedin.com/company/let-s-bunk-today/'
          target='_blank'
          rel='noreferrer'
          className='flex btn  bg-zinc-700 hover:shadow-xl rounded-full py-2 px-4 text-sm lg:text-lg items-center gap-1 border-2 border-zinc-800 text-red-200 hover:bg-zinc-800 hover:border-2 hover:border-red-300'
        >
          LinkedIn
          <FiArrowRight className='arrow' />
        </a>{' '}
        <a
          href='https://www.instagram.com/lets_bunk_today/'
          target='_blank'
          rel='noreferrer'
          className='flex btn bg-zinc-700 hover:shadow-xl rounded-full py-2 text-sm lg:text-lg px-4 items-center gap-1 border-2 border-zinc-800 text-red-200 hover:bg-zinc-800 hover:border-2 hover:border-red-300'
        >
          Instagram <FiArrowRight className='arrow' />
        </a>
      </div>
    </div>
  );
};

const AboutBlock = () => (
  <Block className='col-span-10 text-2xl leading-snug'>
    <p>
      <p className='text-zinc-400 text-base lg:text-lg'>
        • Established and led the thriving "Let's Bunk Today" community,
        <span className='font-semibold  text-white'>
          {' '}
          attracting over 15,000+ visitors to date.
        </span>
      </p>
      {<br></br>}
      <p className='text-base lg:text-lg text-zinc-400'>
        • Curated and managed a comprehensive collection of{' '}
        <span className='font-semibold  text-white'>
          1000+ resources spanning various domains.
        </span>
      </p>
      {<br></br>}
      <p className='text-base lg:text-lg text-zinc-400'>
        • Received{' '}
        <span className='font-semibold text-white'>positive feedback</span> from
        community members, acknowledging the platform's value and impact.
      </p>
    </p>
  </Block>
);

const LocationBlock = () => (
  <Block className='flex col-span-5 items-center gap-4 md:col-span-3'>
    <FiMapPin className='text-3xl' />
    <p className='text-center text-sm lg:text-lg text-zinc-400'>Remote</p>
  </Block>
);

const TimeBlock = () => {
  const startDate = new Date('2022-11-01'); // Start date: November 2022
  const currentDate = new Date(); // Current date

  // Calculate the difference in years, months, and days
  const calculateDuration = (start, end) => {
    const startYear = start.getFullYear();
    const startMonth = start.getMonth();
    const startDay = start.getDate();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth();
    const endDay = end.getDate();

    let years = endYear - startYear;
    let months = endMonth - startMonth;
    let days = endDay - startDay;

    if (days < 0) {
      months -= 1;
      days += new Date(endYear, endMonth, 0).getDate();
    }
    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months, days };
  };

  const duration = calculateDuration(startDate, currentDate);

  return (
    <Block className='flex col-span-5 items-center gap-4 md:col-span-3'>
      <FiClock className='text-3xl' />
      <p className='text-center text-sm lg:text-lg text-zinc-400'>
        {duration.years} year {duration.months} months
      </p>
    </Block>
  );
};

export default Project;
