import React from 'react';
import { motion } from 'framer-motion';

const AnimatedImages = ({ side, images }) => {
  const spacing = 400; // Vertical spacing between images
  const centerX = side === 'left' ? -500 : 350; // Center X position for left/right
  const initialTop = side === 'left' ? 120 : 120; // Initial top position for left/right

  return (
    <>
      {images.map((image, index) => {
        const top = initialTop + index * spacing; // Calculate vertical position

        return (
          <motion.img
            key={index}
            src={image.src}
            alt={image.alt}
            className={`absolute ${image.className}`}
            style={{
              left: `calc(50% + ${centerX}px)`,
              top: `${top}px`,
              transform: 'translate(-50%, -50%)',
              height: image.height,
            }}
            animate={{ y: [0, 20, 0] }}
            transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
          />
        );
      })}
    </>
  );
};

export default AnimatedImages;
