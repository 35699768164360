import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './components/common/Layout';
import DevelopmentProjects from './components/projects/DevelopmentProjects';
import DesignProjects from './components/projects/DesignProjects';

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='/' element={<Layout />} />
        </Routes>
        <Routes>
          <Route path='/devprojects' element={<DevelopmentProjects />} />
        </Routes>{' '}
        <Routes>
          <Route path='/designprojects' element={<DesignProjects />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
