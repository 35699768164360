import React, { useState, useRef } from 'react';
import { motion, useTransform, useScroll } from 'framer-motion';
import ExperienceEJY from './ExperienceEJY';
import ExperienceLBT from './ExperienceLBT';
import ExperienceHeeblo from './ExperienceHeeblo';
import ExperienceKasi from './ExperienceKasi';
import { FaArrowRight } from 'react-icons/fa';
import BotComponent from '../home/BotComponent';
import ImageMotion from '../common/ImageMotion';
import MajaAyega from '../../assets/images/MajaAyega.png';

const Experience = ({ id }) => {
  const [isBotVisible, setIsBotVisible] = useState(false);

  const handleOpenBot = () => {
    setIsBotVisible(true);
  };

  const handleCloseBot = () => {
    setIsBotVisible(false);
  };

  return (
    <div id={id} className='bg-neutral-800'>
      <HorizontalScrollCarousel id={id} onOpenBot={handleOpenBot} />
      <BotComponent isVisible={isBotVisible} onClose={handleCloseBot} />{' '}
      {/* Render BotComponent */}
    </div>
  );
};

const HorizontalScrollCarousel = ({ id, onOpenBot }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ['1%', '-95%']);

  return (
    <>
      <section ref={targetRef} className='relative h-[200vh] bg-zinc-950'>
        <div className='sticky top-0 left-10 flex items-center overflow-hidden'>
          <p className='bg-zinc-950 pt-10 mx-10 mt-[-15rem] flex justify-center text-white text-5xl py-4'>
            My <span className='name'> {'  '}Experience </span>
          </p>
          <motion.div style={{ x }} className='flex gap-4'>
            <ExperienceKasi />
            <ExperienceLBT />
            <ExperienceHeeblo />
            <ExperienceEJY />
            <CallToAction onOpenBot={onOpenBot} />{' '}
          </motion.div>
        </div>
      </section>
    </>
  );
};

const CallToAction = ({ onOpenBot }) => (
  <div className='flex flex-col items-center justify-center bg-zinc-950 p-10 pl-0 pr-20 self-center'>
    <p className='text-white text-4xl name font-bold mb-4 text-center'>
      Let's Work Together
      <ImageMotion ImageSrc={MajaAyega} />
    </p>
    <button
      onClick={(e) => {
        e.preventDefault();
        onOpenBot(); // Open bot when button is clicked
      }}
      className='flex btn m-4 bg-white rounded-full hover:shadow-2xl text-sm lg:text-lg py-2 px-4 items-center gap-1 border-2 border-zinc-800 text-black hover:bg-balck hover:border-2 hover:bg-black hover:text-white'
    >
      Get Started <FaArrowRight className='arrow ml-4' />
    </button>
  </div>
);

export default Experience;
