import React from 'react';
import Footer from '../common/Footer';
import ProjectHighlightsDesign from './ProjectHighlightsDesign';

const DesignProjects = () => {
  return (
    <section className=''>
      <ProjectHighlightsDesign />
      <Footer />
    </section>
  );
};

export default DesignProjects;
