import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import EngageGPT from '../../assets/images/EngageGPT.png';
import MaitJournals from '../../assets/images/MaitJournals.jpeg';
import Natours from '../../assets/images/Natours.jpeg';
import Omnifood from '../../assets/images/Omnifood.jpeg';
import Letsbunktoday from '../../assets/images/Letsbunktoday.png';
import NSB from '../../assets/images/NSB.png';
import Unidrafters from '../../assets/images/Unidrafters.png';

const SpringModal = ({
  isOpen,
  setIsOpen,
  title,
  description,
  techStack,
  githubLink,
  liveLink,
  userCount,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className='bg-slate-900/20 backdrop-blur p-2 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer'
        >
          <motion.div
            initial={{ scale: 0, rotate: '12.5deg' }}
            animate={{ scale: 1, rotate: '0deg' }}
            exit={{ scale: 0, rotate: '0deg' }}
            onClick={(e) => e.stopPropagation()}
            className='bg-gradient-to-br from-violet-600 to-indigo-600 text-white p-6 rounded-lg w-full self-center m-auto max-w-xl shadow-xl cursor-default relative overflow-hidden'
          >
            <div className='relative z-10 '>
              <h3 className=' text-2xl lg:text-3xl font-bold text-center mb-2'>
                {title}
              </h3>
              <p className='text-center mb-2 mt-2'>{description}</p>
              <div className='flex flex-wrap mb-4 mt-4 justify-center'>
                {techStack.map((tech, index) => (
                  <span
                    key={index}
                    className='mr-2 mb-2 rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700'
                  >
                    {tech}
                  </span>
                ))}
              </div>
              {userCount && (
                <p className='mb-4 bg-blue-500 p-2 w-fit px-6 text-white justify-center m-auto  rounded-full text-center'>
                  Visitors: <strong>{userCount}+</strong>
                </p>
              )}
              <div className='flex justify-between items-center mb-4'>
                <a
                  href={githubLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded text-center mx-2'
                >
                  GitHub
                </a>
                <a
                  href={liveLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded text-center mx-2'
                >
                  Live
                </a>
              </div>
              <button
                onClick={() => setIsOpen(false)}
                className='bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded'
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Card = ({
  containerRef,
  src,
  alt,
  top,
  left,
  rotate,
  className,
  onClick,
}) => {
  const [zIndex, setZIndex] = useState(0);

  const updateZIndex = () => {
    const els = document.querySelectorAll('.drag-elements');

    let maxZIndex = -Infinity;

    els.forEach((el) => {
      let zIndex = parseInt(
        window.getComputedStyle(el).getPropertyValue('z-index')
      );

      if (!isNaN(zIndex) && zIndex > maxZIndex) {
        maxZIndex = zIndex;
      }
    });

    setZIndex(maxZIndex + 1);
  };

  return (
    <motion.img
      onMouseDown={updateZIndex}
      onClick={onClick}
      style={{
        top,
        left,
        rotate,
        zIndex,
      }}
      className={twMerge(
        'drag-elements absolute w-64 bg-neutral-200 p-1 pb-4 cursor-pointer',
        className
      )}
      src={src}
      alt={alt}
      drag
      dragConstraints={containerRef}
      dragElastic={0.65}
    />
  );
};

const ProjectHighlights = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const containerRef = useRef(null);

  const handleCardClick = (details) => {
    setProjectDetails(details);
    setIsOpen(true);
  };
  return (
    <section className='relative grid h-[103vh] w-full place-content-center overflow-hidden bg-neutral-950'>
      <h2 className='relative z-0 text-[20vw] font-black text-neutral-800 md:text-[200px]'>
        Web Development<span className='text-indigo-500'>.</span>
      </h2>
      <div className='absolute inset-0 z-10' ref={containerRef}>
        <Card
          containerRef={containerRef}
          src={NSB}
          alt='NSB'
          rotate='4deg'
          top='60%'
          left='58%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'NSB : Nisha Shringar Bhawan (Team Project)',
              description:
                "An E-commerce App for our own shop, I've Developed Backend for this App",
              techStack: [
                'Flutter',
                'NodeJS',
                'Exprees',
                'MongoDB',
                'Git',
                'Github',
                'Figma',
                'Render.com',
              ],
              githubLink: 'https://github.com/Bharat0709/Growcery',
            })
          }
        />{' '}
        <Card
          containerRef={containerRef}
          src={Natours}
          alt='Natours'
          rotate='6deg'
          top='5%'
          left='45%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Natours',
              description:
                'Tours Booking Website, Desktop only, Can take a while to load',
              techStack: [
                'NodeJS',
                'Express',
                'MongoDB',
                'Pug',
                'Stripe',
                'Git',
                'Github',
                'Render.com',
              ],
              githubLink: 'https://github.com/Bharat0709/Natours',
              liveLink: 'https://natours-ceg2.onrender.com',
            })
          }
        />
        <Card
          containerRef={containerRef}
          src={MaitJournals}
          alt='MAIT Journals'
          rotate='12deg'
          top='25%'
          left='50%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'MAIT Journals',
              description: 'Project given by Professor of my college',
              techStack: [
                'HTML',
                'CSS',
                'Javasvript',
                'Git',
                'Github',
                'Figma',
              ],
              githubLink: 'https://github.com/Bharatpahwa/MAIT-Journals',
              liveLink: 'https://bharatpahwa.github.io/MAIT-Journals/',
            })
          }
        />
        <Card
          containerRef={containerRef}
          src={Unidrafters}
          alt='UniDrafters'
          rotate='12deg'
          top='32%'
          left='37%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'UniDrafters (Project In Progress...)',
              description:
                'A website to help College Students Buy and Sell Drafters',
              techStack: [
                'React',
                'Firebase',
                'Google Auth',
                'Razorpay',
                'Tailwind CSS',
                'Vercel',
                'Figma',
                'Git',
                'Github',
              ],
              githubLink: 'https://github.com/Bharat0709/UniDrafters',
              liveLink: 'https://uni-drafters.vercel.app',
            })
          }
        />
        <Card
          containerRef={containerRef}
          src={Omnifood}
          alt='Example image'
          rotate='-6deg'
          top='40%'
          left='10%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Omnifood Website',
              description: 'Project Made to Master CSS Properties',
              techStack: ['HTML', 'CSS', 'Javascript', 'Git', 'Github'],
              githubLink: 'https://github.com/Bharatpahwa/OMNIFOOD-PROJECT',
              liveLink: 'https://bharatpahwa.github.io/OMNIFOOD-PROJECT/',
            })
          }
        />
        <Card
          containerRef={containerRef}
          src={EngageGPT}
          alt='EngageGPT'
          rotate='-18deg'
          top='12%'
          left='10%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'EngageGPT - AI for LinkedIn',
              description: 'A Chrome extension with many amazing features',
              techStack: [
                'React',
                'Tailwind CSS',
                'Framer Motion',
                'NodeJS',
                'CSS',
                'HTML',
                'Firebase',
                'MailGun',
                'Express',
                'MongoDB',
                'Javascript',
                'REST API',
                'Gemini',
                'ChatGPT',
                'Google Analytics',
                'Figma',
              ],
              githubLink: 'https://github.com/Bharat0709/EngageGPT',
              liveLink: 'https://engagegpt.in',
              userCount: '1200+',
            })
          }
        />{' '}
        <Card
          containerRef={containerRef}
          src={Letsbunktoday}
          alt='LetsBunkTOday'
          rotate='-18deg'
          top='60%'
          left='10%'
          className='w-[20rem] md:w-[30rem] rounded-xl'
          onClick={() =>
            handleCardClick({
              title: 'Lets Bunk Today Website',
              description: 'Consists of 1000+ Tech Resouces at one place',
              techStack: [
                'HTML',
                'CSS',
                'Javascript',
                'React',
                'Git',
                'Github',
                'Figma',
                'Google Analytics',
              ],
              githubLink: 'https://github.com/Bharat0709/LBT',
              liveLink: 'https://letsbunktoday.tech',
              userCount: 15000,
            })
          }
        />{' '}
      </div>

      <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} {...projectDetails} />
    </section>
  );
};

export default ProjectHighlights;
