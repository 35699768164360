import React, { useState } from 'react';
import useMeasure from 'react-use-measure';
import {
  useDragControls,
  useMotionValue,
  useAnimate,
  motion,
} from 'framer-motion';
import HoverImageLinks from './AnimatedEdu';
import { FiArrowRight } from 'react-icons/fi'; // Import upward arrow icon
import StillHere from './Stillhere';
import ImageMotion from '../common/ImageMotion';
import PaaniLelo from '../../assets/images/PaaniLelo.jpg';

const Education = ({ id }) => {
  const [open, setOpen] = useState(false);
  return (
    <div id={id} className='w-full'>
      <button
        onClick={() => setOpen(true)}
        className='flex btn w-fit px-8 justify-center m-auto cursor-pointer bg-black rounded-full text-sm lg:text-lg py-2 items-center gap-1 border-2 border-zinc-800 text-white hover:text-black hover:shadow-xl hover:bg-white hover:border-2 hover:border-zinc-800'
      >
        Educational Background <FiArrowRight className='arrow ml-4' />
      </button>{' '}
      <div className='grid place-content-center'>
        <DragCloseDrawer open={open} setOpen={setOpen}>
          <div className='mx-auto space-y-4 text-neutral-600'>
            <HoverImageLinks />
          </div>
        </DragCloseDrawer>
      </div>
      <StillHere />
      <ImageMotion ImageSrc={PaaniLelo} />
    </div>
  );
};

const DragCloseDrawer = ({ open, setOpen, children }) => {
  const [scope, animate] = useAnimate();
  const [drawerRef, { height }] = useMeasure();

  const y = useMotionValue(0);
  const controls = useDragControls();

  const handleClose = async () => {
    animate(scope.current, { opacity: [1, 0] });

    const yStart = typeof y.get() === 'number' ? y.get() : 0;

    await animate('#drawer', { y: [yStart, height] });

    setOpen(false);
  };

  return (
    <>
      {open && (
        <motion.div
          ref={scope}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={handleClose}
          className='fixed inset-0 z-50 bg-neutral-950/70'
        >
          <motion.div
            id='drawer'
            ref={drawerRef}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: '100%' }}
            animate={{ y: '0%' }}
            transition={{ ease: 'easeInOut' }}
            className='absolute bottom-0 h-[75vh] w-full overflow-hidden rounded-t-3xl bg-neutral-900'
            style={{ y }}
            drag='y'
            dragControls={controls}
            onDragEnd={() => {
              if (y.get() >= 100) {
                handleClose();
              }
            }}
            dragListener={false}
            dragConstraints={{
              top: 0,
              bottom: 0,
            }}
            dragElastic={{
              top: 0,
              bottom: 0.5,
            }}
          >
            <div className='absolute left-0 right-0 top-0 z-10 flex justify-center bg-neutral-900 p-4'>
              <button
                onPointerDown={(e) => {
                  controls.start(e);
                }}
                className='h-2 w-14 cursor-grab touch-none rounded-full bg-neutral-700 active:cursor-grabbing'
              ></button>
            </div>
            <div className='relative z-0 h-full overflow-y-scroll p-4 pt-12'>
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default Education;
